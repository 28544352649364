exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-bones-js": () => import("./../../../src/pages/work/bones.js" /* webpackChunkName: "component---src-pages-work-bones-js" */),
  "component---src-pages-work-designsystems-js": () => import("./../../../src/pages/work/designsystems.js" /* webpackChunkName: "component---src-pages-work-designsystems-js" */),
  "component---src-pages-work-eurostar-js": () => import("./../../../src/pages/work/eurostar.js" /* webpackChunkName: "component---src-pages-work-eurostar-js" */),
  "component---src-pages-work-healthbox-js": () => import("./../../../src/pages/work/healthbox.js" /* webpackChunkName: "component---src-pages-work-healthbox-js" */),
  "component---src-pages-work-kidwonder-js": () => import("./../../../src/pages/work/kidwonder.js" /* webpackChunkName: "component---src-pages-work-kidwonder-js" */),
  "component---src-pages-work-newbeeper-js": () => import("./../../../src/pages/work/newbeeper.js" /* webpackChunkName: "component---src-pages-work-newbeeper-js" */),
  "component---src-pages-work-newlook-js": () => import("./../../../src/pages/work/newlook.js" /* webpackChunkName: "component---src-pages-work-newlook-js" */)
}

